<template>
  <div class="breadcrumbnav">
    <!-- <div class="breadcrum">
      <div class="breadcrum_text"><b>首页</b> > 科技创新</div>
      <div class="breadcrum_text company"></div>
    </div> -->
    <div class="tag">
      <span class="tag_head">年度目标</span>
      <span :class="['tag_body_all', showTotal?'':'tag_body_hide']">{{newestval}}</span>
    </div>

    <div class="bottom_tag" @click="switchArrow()">年度目标<i :class="[showTotal?'gg-chevron-up':'gg-chevron-down']"></i></div>
  </div>
</template>

<script>
import {
  newest
} from "@/api/wel";
export default {
  name: 'Breadcrumbnav',
  props: ['value2'],
  data() {
    return {
      showTotal: false,
      newestval: '以创建国家高新技术产业开发区为统领，围绕科创指数争先晋位夺鼎（科技创新鼎）目标，加快构建县内县外两个“3+N”创新动力体系，更高起点实施“三高三百”计划，开展六大行动，接续打造浙西南科创高地。'
    }
  },
  mounted () {
    this.getnewest()
  },
  watch: {
    'value2' (newVal) {
      this.getnewest()
    }
  },
  methods: {
    switchArrow() {
      this.showTotal = !this.showTotal
    },
    getnewest(){
        newest({yearDate:this.value2}).then(res=>{
          if(res.data.code==200){
            this.newestval = res.data.data.slogan
          }
        })
      },
  }
};
</script>

<style lang="scss" scoped>
.breadcrumbnav {
  // height: 144px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 6px 1px rgba(12, 55, 146, 0.15);
  margin-bottom: 24px;
  padding: 0px 24px 18px;
  position: relative;
  .breadcrum {
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    &_text {
      line-height: 14px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      // font-weight: bold;
      color: #333333;
    }
    .company {
      font-size: 13px;
    }
  }
  .tag {
    text-align: left;
    margin: 24px 0;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2690f3;
    display: flex;
    align-items: center;
    .tag_head {
      width: 68px;
    }
    .tag_head::after {
      content: '|';
      margin: 0 6px;
      color: #cccccc;
      font-weight: 100;
    }
    .tag_body_all {
      line-height: 1rem;
      font-size: 0.75rem;
      flex: 1;
      // max-height: 120px;
      // transition: all 1s;
    }
    .tag_body_hide {
      max-height: 1rem;
      // transition: all 1s;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .tag::before {
    content: '';
    display: inline-block;
    width: 0.75rem;
    height: 0.6875rem;
    background: url('../../assets/breadcrumbnav_icon.png') no-repeat;
    background-size: 0.75rem 0.6875rem;
    margin-right: 3.5px;
  }

  .bottom_tag {
    cursor: pointer;
    width: 100px;
    height: 1.125rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
    position: absolute;
    color: #999999;
    font-weight: 500;
    background-color: #f1f1f1;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .bottom_tag::before {
    content: '';
    width: 0px;
    height: 0px;
    border: 0.5625rem solid #999999;
    position: absolute;
    left: -1.125rem;
    border-right: 0.5625rem solid #f1f1f1;
    border-bottom: 0.5625rem solid #f1f1f1;
    border-top: 0.5625rem solid #fff;
    border-left: 0.5625rem solid #fff;
  }
  .bottom_tag::after {
    content: '';
    width: 0px;
    height: 0px;
    border: 0.5625rem solid #999999;
    position: absolute;
    right: -1.125rem;
    border-left: 0.5625rem solid #f1f1f1;
    border-bottom: 0.5625rem solid #f1f1f1;
    border-top: 0.5625rem solid #fff;
    border-right: 0.5625rem solid #fff;
  }
  .gg-chevron-down .gg-chevron-down {
    margin-left: 5px;
  }
}
</style>
